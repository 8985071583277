.video-react {
  background-color: black !important;
}

.video-react-controls-enabled {
  padding-top: 0% !important;
  overflow: hidden !important;
  height: 100% !important;
}

